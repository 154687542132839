import React from 'react';
import { graphql } from 'gatsby';
import polyline from '@mapbox/polyline';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Totals from '../components/totals';

import style from '../pages/pages.module.scss';

export default ({ data }) => {
  const post = data.markdownRemark;

  const polylinePrefix =
    post.frontmatter.dayNumber % 2 === 0
      ? '&path=color:0x333FFFff%7Cweight:2%7Cenc:'
      : '&path=color:0xFC1B17ff%7Cweight:2%7Cenc:';

  const activities = data.allStravaActivity.edges.map(cv => {
    const currCoords = polyline
      .decode(cv.node.activity.map.summary_polyline)
      .map(point => {
        return {
          lat: point[0],
          lng: point[1],
        };
      });
    return {
      frontmatter: {
        ...post.frontmatter,
      },
      totals: {
        coords: currCoords,
      },
    };
  });

  const activityTotals = data.allStravaActivity.edges.reduce((acc, cv) => {
    const stravaData = cv.node.activity;

    const currCoords = polyline
      .decode(stravaData.map.summary_polyline)
      .map(point => {
        return {
          lat: point[0],
          lng: point[1],
        };
      });
    return {
      mapPolyline:
        (acc.mapPolyline || '') +
        polylinePrefix +
        stravaData.map.summary_polyline,
      distance: (acc.distance || 0) + stravaData.distance,
      total_elevation_gain:
        (acc.total_elevation_gain || 0) + stravaData.total_elevation_gain,
      moving_time: (acc.moving_time || 0) + stravaData.moving_time,
      stravaLink: `https://www.strava.com/activities/${stravaData.id}`,
      fullPoly: acc.fullPoly ? [...acc.fullPoly, ...currCoords] : currCoords,
    };
  }, {});

  const staticMap = `https://maps.googleapis.com/maps/api/staticmap?size=600x313&scale=2&maptype=roadmap&format=jpg&key=${data.site.siteMetadata.googleMapsKey}${activityTotals.mapPolyline}`;
  console.log('staticMap:', staticMap);

  const padDayNumber = (num, size = 2) => {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  };

  const paddedDayNumber = padDayNumber(post.frontmatter.dayNumber);

  return (
    <Layout>
      <SEO
        title={`Day ${post.frontmatter.dayNumber}: ${post.frontmatter.title} – Ride Across America`}
        description={post.excerpt}
        image={`/images/day/meta-day-${paddedDayNumber}.jpg`}
        pathname={`/day/${paddedDayNumber}/`}
      />
      <div className={[style.page].join(' ')}>
        <div className={style.feature_odd}>
          <div className={style.container}>
            <h1>{`Day ${paddedDayNumber}: ${post.frontmatter.title}`}</h1>
            <Totals
              data={activityTotals}
              activities={activities}
              googleMapsKey={data.site.siteMetadata.googleMapsKey}
            />

            <hr />
            <div
              dangerouslySetInnerHTML={{ __html: post.html }}
              className={style.mainText}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $activityIds: [Float]) {
    site {
      siteMetadata {
        googleMapsKey
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        dayNumber
      }
    }
    allStravaActivity(filter: { activity: { id: { in: $activityIds } } }) {
      totalCount
      edges {
        node {
          activity {
            id
            name
            moving_time
            distance
            total_elevation_gain
            start_latlng
            end_latlng
            map {
              summary_polyline
            }
          }
        }
      }
    }
  }
`;
